import React from 'react';

import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';

export const ErrorBoundaryComponent = ({ error, resetErrorBoundary }: any) => {
  const navigation = useNavigate();
  const { storeId, harvestId } = useParams();
  return (
    <ErrorStyled role="alert">
      <p>Oops, Something went wrong....</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary} variant="outlined">
        Try again
      </Button>
      <pre>Or</pre>
      <Button
        onClick={() => navigation(`/${storeId}/${harvestId}`)}
        variant="outlined"
      >
        Go to main
      </Button>
    </ErrorStyled>
  );
};

const ErrorStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 24px;
    font-weight: 700;
  }
  pre {
    font-size: 22px;
  }
  button {
    font-size: 20px;
  }
`;
