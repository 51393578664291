import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const MenuStyled = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
  height: 30px;
  border-top: 0.3px solid #ffffff;
  background-color: ${({ theme }: any) => theme.menuBg};
  color: ${({ theme }: any) => theme.menuColor};
  padding: 0 15px;

  & > div {
    max-width: 1128px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LinkStyled = styled(Link)`
  float: right;
  color: ${({ theme }: any) => theme.menuColor};
  padding-left: 32px;
  text-decoration: none;
`;

export const CatName = styled.span`
  line-height: 30px;
  font-size: 12px;
  font-weight: 700;
`;

export const StrLoc = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 30px;
  font-size: 12px;
  font-weight: 700;

  .injected-svg {
    fill: ${({ theme }: any) => theme.menuColor};
    height: 15px;
  }

  & > p {
    margin-left: 14px;
  }
`;
