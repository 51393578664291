import React from 'react';

import { IProduct } from '../../types';
import DOMPurify from 'dompurify';
import { Code } from 'react-content-loader';
import { PopularProducts } from '../../components/PopularProducts';
import { useBrandRoute } from './useBrandRoute';
import { BrandRouteStyled } from './styles';

export const BrandRoute = () => {
  const { translation, data, loading } = useBrandRoute();

  const sanitizedData = (data: any) => ({
    __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['script'] }),
  });

  if (loading) {
    return (
    <div style={{ marginTop: 24 }}>
      <Code />
    </div> );
   } else {

    const products = data.getBrandPage?.Products as IProduct[];

    const brandData = data.getBrandPage;

    return (
      <BrandRouteStyled>
        <div className="brandPage-container">
        <h1>{data.getBrandPage.Title}</h1>
        </div>
        
        <React.Fragment key="top">
        <div dangerouslySetInnerHTML={sanitizedData(brandData.TopContent)} className='top'>
        </div>
        </React.Fragment>

        { products && products.length > 1 ? 

        <div className="brandProducts">
          <PopularProducts
            products={products}
            className="swiper3"
            title={translation?.getLocalization.BrandProducts}
          ></PopularProducts>
        </div>

         : '' }


        <React.Fragment key="bottom">
        <div dangerouslySetInnerHTML={sanitizedData(brandData.BottomContent)} className='bottom'>
        </div>
        </React.Fragment>

      </BrandRouteStyled>
    );
   }
};
