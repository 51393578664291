import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_BRANDPAGE_BY_ID, GET_LOCALIZED } from '../../queries';
import { contentSquereCustomTag, artificialPageview } from '../../helpers'
import { useConfiguration } from '../../hooks/useConfiguration';

export const useBrandRoute = () => {
  const { storeId = '', brand, harvestId = '' } = useParams();

  const fullpath = '/Brand Pages/' + brand
  const { data, loading } = useQuery(GET_BRANDPAGE_BY_ID,
    {
      variables: {
        fullpath: fullpath,
      },
    }
  );

  const {
    LanguageVersion
  } = useConfiguration( { storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );
  
  React.useEffect(() => {
    contentSquereCustomTag(1,'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2,'pageType','BrandPage','')
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
  }, [])

  return {
    loading,
    data,
    translation
  };
};
