import React from "react";
import { ChipWithImageContainerStyled, ChipsContainer } from ".././styles";
import { ChipWithImage } from "../../ChipWithImage";
import { imgSrcByEnv } from "../../../../config/imgSrcByEnv";

export const PillFiltersWithImageComponent = ({
  filters,
  getCustomizedFilterData,
}: {
  filters?: any[];
  getCustomizedFilterData: Function;
}) => {

  return (
    <>
      {filters &&
        <ChipWithImageContainerStyled>
          {filters.map((filterElement: any, index: number) => {
            const filterData = getCustomizedFilterData(filterElement);

            return <ChipWithImage
              key={filterData.key}
              title={filterData.title}
              colors={filterData.colors}
              backgroundImage={imgSrcByEnv() + filterElement.Thumbnail?.fullpath}
              onClick={() => {
                filterData.clickHandler();
              }}
              isExpand={false}
              background="#ffffff"
            />
          }
          )}
        </ChipWithImageContainerStyled>
      }
    </>
  );
};