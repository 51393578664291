import styled from '@emotion/styled';
import { theme } from '../../theme';

export const FiltersStyled = styled('div')`
  width: 100%;
`;

export const PopularFiltersStyled = styled.div<{
  isLessThanSixItems?: boolean;
}>`
  display: flex;
  gap: 24px;
  overflow-x: auto;
  

  .filter {
    text-align: center;

    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-right: auto;
    }

    img {
      height: auto;
      max-height: 168px;
      width: 168px;
      object-fit: contain;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      white-space: normal;
      height: 28px;
    }
  }
`;

export const Header = styled.h1`
  margin-top: 26px;
  max-width: 181px;
  line-height: 29px;
  font-weight: 700;
  font-size: 24px;
  color: ${theme.target.black};
  margin-bottom: 24px;
`;

export const ChipWithImageContainerStyled = styled.div`
  display: flex;
  margin: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  gap: 10px;
  scrollbar-width: none;
`;

export const ChipsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 16px;
  white-space: nowrap;
  margin-top: 10px;
  padding: 20px 5px 10px;
  & > span:first-of-type {
    margin-left: auto;
  }
  & > span:last-of-type {
    margin-right: auto;
  }
`;
