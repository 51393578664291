import React from 'react';
import styled from '@emotion/styled';

type TColors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  colors?: TColors;
  background?: TColors;
  backgroundImage?: string;
}

export const ChipWithImage = ({
  title,
  onClick,
  backgroundImage,
  isExpand,
  colors,
  background,
}: // addinitonalIcon,
IProps) => {
  return (
    <ChipWithImageStyled
      onClick={onClick}
      colors={colors}
      background={background}
    >
      <ImageWrapper>
        <ImageStyled src={backgroundImage} alt={title} />
      </ImageWrapper>
      <TitleStyled>{title}</TitleStyled>
      {/* {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />} */}
    </ChipWithImageStyled>
  );
};

const TitleStyled = styled.p``;
const ImageWrapper = styled.div`
  display: flex;
  height: 80%;
`;
const ImageStyled = styled.img`
  max-width: 95px;
  width: 28vw;
  height: auto;
  padding: 3px;
  object-fit: contain;
  border-radius: 5px;
`;
const ChipWithImageStyled = styled.span<{
  colors?: TColors;
  background?: TColors;
}>`
  border: ${({ background, colors }) =>
    colors === 'dark' ? '1px solid #DBDBDB' : '1px solid #FFF'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 28vw;
  height: auto;
  padding-bottom: 5px;
  margin-top: 5px;
  /* /* cursor: pointer; */

  .additional-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 15px;
      fill: #000;
      stroke: #000;
    }
  }
  .expand {
    margin-left: 7px;
  }
`;
