import React from 'react';

import styled from '@emotion/styled';
import { sortByRating } from '../../helpers';
import { ProgressBar } from '../ProgressBar';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { useQuery } from '@apollo/client';

interface Iprops {
  totalReviews: number;
}

export const ReviewsGraph = ({ totalReviews }: Iprops) => {
  const [reviews, setReviews] = React.useState<any>([]);

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  React.useEffect(() => {
    const mockReviews = [];
    for (let i = 0; i < totalReviews; i++) {
      mockReviews.push({ rating: Math.ceil(Math.random() * 5) });
    }
    setReviews(mockReviews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reviewsByRating = sortByRating(reviews);

  return (
    <ReviewsGraphStyled>
      {Object.entries(reviewsByRating).map(([key, value]) => (
        <div className="review-line" key={key}>
          <span>{key} {translation?.getLocalization.Stars}</span>
          <ProgressBar max={totalReviews} current={value} />
          <span>{value}</span>
        </div>
      ))}
    </ReviewsGraphStyled>
  );
};

const ReviewsGraphStyled = styled.div`
  .review-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
  }
`;
