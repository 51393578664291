import React from "react";
import styled from "@emotion/styled";

interface Iprops {
  current: number;
  max: number;
}

export const ProgressBar = ({ current, max }: Iprops) => {
  const progress = (current / max) * 100;
  return (
    <ProgressBarStyled progress={progress}>
      <div></div>
    </ProgressBarStyled>
  );
};

interface IpropgressProps {
  progress: number;
}

const ProgressBarStyled = styled.div<IpropgressProps>`
  background: #e4e4e5;
  height: 6px;
  width: 80%;
  border-radius: 10px;
  div {
    display: inline-block;
    height: 100%;
    width: ${({ progress }) => (progress ? `${progress}%` : "0")};
    background: #73747a;
    border-radius: 10px;
    margin-bottom: 6px;
  }
`;
