import { useQuery } from '@apollo/client';
import { GET_CONFIGURATION } from '../queries';
import { Iconfig } from '../types/config';

export const useConfiguration = ({
  storeId,
  harvestId,
}: {
  storeId: string;
  harvestId: string;
}): Iconfig => {
  const { data, loading, error } = useQuery(GET_CONFIGURATION, {
    variables: {
      fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
    },
    skip: !storeId.length,
  });

  if (!loading && storeId.length && !error) {
    const template = data.getHarvest.Template;

    return {
      loading,
      ...template,
    };
  } else {
    return { loading, error };
  }
};
