import { useMainRoute } from './useMainRoute';
import { ThemeProvider } from '@emotion/react';
import { HomeCategoriesSlider } from './homescreen/HomeCategoriesSlider';
import { HomeDeliverySection } from './homescreen/HomeDeliverySection';
import { HomeHeroSection } from './homescreen/HomeHeroSection';
import { HomeProductsSlider } from './homescreen/HomeProductsSlider';
import { HomeBannerSection } from './homescreen/HomeBannerSection';
import { HomeCommitmentsSection } from './homescreen/HomeCommitmentsSection';
import { HomeOfferDetails } from './homescreen/HomeOfferDetails';
import { HomeFooterSection } from './homescreen/HomeFooterSection';
import { ICategory, IProduct } from '../../types';
import { theme } from '../../theme';
import styled from '@emotion/styled';
import { PopularProducts } from '../../components/PopularProducts';
import DOMPurify from 'dompurify';
import { Carousel } from '../../components/Carousel';
interface IShuffledProducts {
  shuffledSimilar: IProduct[];
  shuffledRecommended: IProduct[];
}

const sanitizedData = (data: any) => ({
  __html: DOMPurify.sanitize(data, { FORBID_TAGS: ['script'] }),
});

export const MainRoute = () => {
  const {
    shuffledProducts,
    categories,
    homePage
  }: {
    shuffledProducts: IShuffledProducts;
    categories?: { node: ICategory }[];
    homePage: any;
  } = useMainRoute();

  return (
    <ThemeProvider theme={theme}>
      <StyledComponentWrapper>
        <HomeCategoriesSlider categories={categories} />
        <HomeDeliverySection categories={categories} />
        { 
        homePage && (
          <style>{homePage.Styles}</style>
        )
        }
        {
        homePage && (
          homePage.Elements.map( (item: any, index: number) => { 
            if (item.__typename == 'fieldcollection_HomePageElementSlider') { 
              return (<PopularProducts products={item.SliderProducts} title={item.Title}></PopularProducts>);
            }
            if (item.__typename == 'fieldcollection_HomePageElementCarousel') { 
              return (<div className="carousel-container">
                <Carousel CarouselItems={item.CarouselItems}></Carousel>
              </div>);
            }
            if (item.__typename == 'fieldcollection_HomePageElementRT') { 
              return (<div dangerouslySetInnerHTML={sanitizedData(item.Content)} className='top'>
            </div>);
            }
          })
          )
        }
        {
        /*<HomeHeroSection />
        <HomeProductsSlider
          shuffledProducts={shuffledProducts}
          title={'Target Circle Week deals for you'}
        />
        <HomeBannerSection />
        <HomeProductsSlider
          shuffledProducts={shuffledProducts}
          title={'Save on, saver'}
        />
        <HomeCommitmentsSection />
        */
        }
        <StyledBottomPartWrapper>
          { /* <HomeOfferDetails /> */ }
          <HomeFooterSection />
        </StyledBottomPartWrapper>
      </StyledComponentWrapper>
    </ThemeProvider>
  );
};

const StyledComponentWrapper = styled.div`
  display: block;
  max-width: 100vw;
`;

const StyledBottomPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  background: linear-gradient(to bottom, #ffffff 0%, #f4f4f4 50%);
`;
