import styled from '@emotion/styled';
import React from 'react';

export const ErrorPage = ({ errorText }: { errorText: string }) => {
  return <ErrorPageStyled>{errorText}</ErrorPageStyled>;
};

const ErrorPageStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 100px;
  font-size: 22px;
  font-weight: 800;
`;
