import { ApolloClient, InMemoryCache } from '@apollo/client';
export const graphqlClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_CLIENT_URL,
  cache: new InMemoryCache({
    // possibleTypes: {
    //   object_ePogTerm_Products: ['object_ePog'],
    // },
    typePolicies: {
      Query: {
        fields: {
          getHarvest: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  headers: {
    'x-api-key': process.env.REACT_APP_GRAPHQL_API_KEY || '',
  },
});
