import React from 'react';

import { useQuery } from '@apollo/client';
import { LIST_ROOT_CATEGORIES } from '../../queries';
import { CatName, LinkStyled, MenuStyled, StrLoc } from './styles';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries'

export const Menu = () => {
  const { storeId = '', harvestId = ''} = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const fullpath = `/ePog Product Data/Terms/${storeId}/Category/${harvestId}`;

  const { data, loading } = useQuery(LIST_ROOT_CATEGORIES, {
    variables: {
      fullpath,
    },
    skip: !storeId?.length || !harvestId?.length,
  });

  if (loading) {
    return <div></div>;
  } else {
    const categories = data?.getObjectFolder?.children;

    return (
      <MenuStyled>
        <div>
          <StrLoc>
            <ReactSVG src="/files/generic/img/pointer.svg" />
            <p>{translation?.getLocalization.StoreLocations}</p>
          </StrLoc>
          <div>
            {categories &&
              categories.map(
                (category: any, index: number) =>
                  category.fullpath && (
                    <LinkStyled
                      key={index}
                      to={`/${storeId}/${harvestId}/category/${
                        category.id
                      }__${category.key.replaceAll(' ', '_')}`}
                    >
                      <CatName>{category.key}</CatName>
                    </LinkStyled>
                  )
              )}
          </div>
        </div>
      </MenuStyled>
    );
  }
};
